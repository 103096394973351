import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  claimActions_setClaimType,
  claimActions_setIsPushedSearchButton,
  claimActions_setPageNumber,
  claimActions_setReset,
  claimActions_setSearchOption,
  claimActions_setSearchText,
  claimActions_setTotalSize } from 'store/modules/actions/claim/claimActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { claimApi } from 'api/apis/claim/claimApi';
import { claimTypeApi } from 'api/apis/claim/claimTypeApi';

import Grid4Body from 'components/layouts/body/Grid4Body';
import { checkEmptyNull } from 'components/checkValues/checkValues';
import { DateTimeFormatKR } from 'components/format/DateTimeFormatKR';

import SearchButtonIcon from 'components/icons/SearchButtonIcon';

import UpdateClaimForm from './components/updateForm';
import DeleteClaimForm from './components/deleteForm';
import CreateClaimForm from './components/createForm';
import NavBar from 'components/nav/NavBar';

import { ClaimStyle } from './Claim.style';
import { TimeCount } from 'pages/monitoring/styles';

const ClaimList = () => {
  const dispatch = useDispatch();
  const { claimReducer, userReducer } = useSelector((state) => state);
  const [_onload, setOnload] = useState('unload');

  const [_claimTypeList, setClaimTypeList] = useState([]);
  const [_claimList, setClaimList] = useState([]);

  const [_createStatus, setCreateStatus] = useState(false);
  const [_updateStatus, setUpdateStatus] = useState(false);
  const [_deleteStatus, setDeleteStatus] = useState(false);
  const [_checkedClaim, setCheckedClaim] = useState(new Set());
  const [_checkedContent, setCheckedContent] = useState(new Set());

  // 첫 페이지 로드
  useEffect(() => {
    getClaimTypeList();
    getClaimList(
      claimReducer.pageNumber - 1, 
      claimReducer.claimTypeId,
      claimReducer.isPushedSearchButton);
    dispatch(pageReducer_getCurrentPage(window.location.pathname));
    setOnload('loaded');
    
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const getClaimTypeList = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    await claimTypeApi.searchClaimType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('getClaimTypeList : ', response);
      setClaimTypeList(() => { return response.data; });
    });
  };

  const getClaimList = async (page, claimTypeId, clear) => {
    const paging = `?page=${page}&size=1000&sort=id,DESC`;
    const BodyToPost = { companyId: userReducer.company.companyId, };

    if (checkEmptyNull(claimTypeId, false)) {
      BodyToPost.customerClaimTypeId = claimTypeId;
    }

    if (clear && checkEmptyNull(claimReducer.searchOption, false) && checkEmptyNull(claimReducer.searchText, false)) {
      BodyToPost[claimReducer.searchOption] = claimReducer.searchText;
    }

    await claimApi.searchAllClaim(paging, BodyToPost).then((response) => {
      if (response === undefined) return;

      if (response.data) {
        setClaimList(() => { return response.data.content; });
        dispatch(claimActions_setTotalSize(response.data.totalElements));
      }
    });
  };
  /* ====================================================================== #5 */
  /* 검색 */
  const actSearch = async () => {
    setCreateStatus(() => { return false; });

    dispatch(claimActions_setPageNumber(1));
    dispatch(claimActions_setIsPushedSearchButton(true));
    await getClaimList(0, claimReducer.claimTypeId, true);
  };

  /* 초기화 */
  const actReset = async () => {
    setCreateStatus(() => { return false; });

    dispatch(claimActions_setReset());
    dispatch(claimActions_setPageNumber(1));
    await getClaimList(0, claimReducer.claimTypeId, false);
  };
  
  /* 체크 */
  const handleCheckedClaim = (id, isChecked) => {
    const checkedClaim = new Set();
    if (isChecked) {checkedClaim.add(id);} else {checkedClaim.delete(id);}
    const checkedClaimIndex = _claimList.findIndex((claim) => claim.customerClaimId === Array.from(checkedClaim)[0]);
    if (checkedClaimIndex !== -1) {
      setTimeout(() => {
        setCheckedContent(_claimList[checkedClaimIndex]);
      }, 1000);
    }
  }; 

  return (
    <ClaimStyle>
      <Grid4Body
        contents={
        <main className='claimList'>
          <NavBar
            title='고객 클레임 관리'
            buttons={
              <>
                {!_createStatus ?
                  (<button className='claim-form-btn' onClick={(e) => { setCreateStatus(() => { return true; }); }}>추가</button>) 
                : null}
                {!_updateStatus ?
                  (<button className='claim-form-btn' onClick={(e) => { setUpdateStatus(() => { return true; }); }}>수정</button>)
                : null}
                {!_deleteStatus ?
                  (<button className='claim-form-btn' onClick={(e) => { setDeleteStatus(() => { return true; }); }}>삭제</button>)
                : null}
              </>
            }
            firstRow={
              <>
                <div className="SearchSection">
                  <div className="ContentCBox">
                    <div className='qna-search'>
                      <select className="SearchOption-detail"
                        value={claimReducer.claimTypeId}
                        onChange={(e) => {
                          dispatch(claimActions_setClaimType(e.target.value));
                          dispatch(claimActions_setPageNumber(1));
                          getClaimList(0, e.target.value, claimReducer.isPushedSearchButton);
                        }}
                      >
                        <option value=''>전체</option>
                        {_claimTypeList.map((claimType) => {
                          return (
                            <option key={claimType.customerClaimTypeId + '_claimType'} value={claimType.customerClaimTypeId}>{claimType.customerClaimTypeName}</option>
                          );
                        })}
                      </select>
                      <select className="SearchOption-detail"
                        value={claimReducer.searchOption}
                        onChange={(e) => { dispatch(claimActions_setSearchOption(e.target.value)); }}
                      >
                        {[
                          { optionValue: '', optionText: '검색옵션' },
                          { optionValue: 'accountName', optionText: '고객명' },
                          { optionValue: 'customerClaimContent', optionText: '클레임 내용' },
                        ].map((option) => {
                          return (<option key={option.optionValue + '_claimSearchOption'} value={option.optionValue} >{option.optionText}</option>);
                        })}
                      </select>
                      <input
                        className="SearchBar"
                        placeholder="Search..."
                        value={claimReducer.searchText}
                        onInput={(e) => { dispatch(claimActions_setSearchText(e.target.value)); }}
                      />
                      <Link onClick={actReset} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 8px' }}>
                        <img src='https://cdn-icons-png.flaticon.com/512/82/82004.png' style={{ width: '16px' }} />
                      </Link>
                      <button onClick={actSearch} className='btn-search'><SearchButtonIcon />검색</button>
                    </div>
                  </div>
                </div>
              </>
            }
          />

          {/* 문의 등록 */}
          {(() => {
            if (_createStatus) {
              return (
                <CreateClaimForm
                  claimTypeList={_claimTypeList}
                  update={actReset}
                  close={(e) => {
                    e.preventDefault();
                    setCreateStatus(() => { return false; });
                  }}
                />
              );
            } else {
              return null;
            }
          })()}

          {/* 문의 수정 */}
          {(() => {
            if (_updateStatus && _checkedClaim) {
              return (
                <UpdateClaimForm
                  claimTypeList={_claimTypeList}
                  updateClaim={_checkedContent}
                  update={actReset}
                  close={(e) => {
                    setUpdateStatus(() => { return false; });
                  }}
                />
              );
            } else {
              return null;
            }
          })()}

          {/* 문의 삭제 */}
          {(() => {
            if (_deleteStatus && _checkedClaim) {
              return (
                <DeleteClaimForm
                  deleteClaim={_checkedContent}
                  update={actReset}
                  close={(e) => {
                    setDeleteStatus(() => { return false; });
                  }}
                />
              );
            } else {
              return null;
            }
          })()}

          {/* 문의 리스트 */}
          <section>
            <div className='claim-title'>
              <p className='claim-check'></p>
              <p className='claim-type'>유형</p>
              <p className='claim-accountName'>고객명</p>
              <p className='claim-content'>내용</p>
              <p className='claim-date'>등록일</p>
            </div>
            <ul>
              {_claimList.map((claim) => {
                return (
                  <>
                    {claim.companyId === userReducer.company.companyId ? (
                      <li key={claim.customerClaimId + '_claim'}>
                        <>
                          <input
                            className='claim-check'
                            type='checkbox'
                            name='checkedClaim'
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              handleCheckedClaim(claim.customerClaimId, isChecked);
                              if (isChecked) {
                                document.querySelectorAll('[name="checkedClaim"]').forEach((checkbox) => {
                                  if (checkbox !== e.target) checkbox.checked = false;
                                });
                              }
                            }}                            
                            onClick={(e) => { e.stopPropagation() }}
                          />
                          <p className='claim-type'>{checkEmptyNull(claim.customerClaimTypeId, '')}</p>
                          <p className='claim-accountName'>{claim.accountName}</p>
                          <p className='claim-content'>{claim.customerClaimContent}</p>
                          <p className='claim-date'>{DateTimeFormatKR(claim.createdDate)}</p>
                        </>
                      </li>
                    ): null}
                  </>
                );
              })}
            </ul>
          </section>
        </main>
      }
      />
    </ClaimStyle>
  );
};

export default ClaimList;

import React from 'react';

import { claimApi } from 'api/apis/claim/claimApi';

const DeleteClaimForm = (props) => {
  /* ====================================================================== #1 */
  /* ====================================================================== #2 */
  /* ====================================================================== #3 */
  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  /* 삭제 */
  const actDelete = async (e) => {
    e.preventDefault();

    const claimId = props.deleteClaim.customerClaimId;

    await claimApi.deleteClaim(claimId).then((response) => {
      if (response === undefined) return;
      console.log('claim.createClaim : ', response);
      alert('클레임을 삭제했습니다.');
      props.update();
      props.close();
    });
  };

  /* ====================================================================== #6 */

  return (
    <section className='claim-form'>
      <div>
        <div className='claim-form-title'>
          <h2>클레임 삭제</h2>
          <button onClick={props.close}>닫기</button>
        </div>
        <p className='claim-form-content'>해당 클레임을 삭제하시겠습니까?</p>
        <button className="submit-delete" onClick={actDelete}>클레임 삭제</button>
      </div>
    </section>
  );
};

export default DeleteClaimForm;
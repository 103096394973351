/* 나라장터 */
/* naraElement - 기준정보/물품정보 */
// 패밀리3 물품정보
import NaraElement from 'pages/naraElement/NaraElement';
// 패밀리5 물품정보
import NaraElementV2 from 'pages/naraElement/NaraElementV2';
import CopyNaraElement from 'pages/naraElement/crud/CopyNaraElement';
import CreateNaraElement from 'pages/naraElement/crud/CreateNaraElement';
import UpdateNaraElement from 'pages/naraElement/crud/UpdateNaraElement';
import DeleteNaraElement from 'pages/naraElement/crud/DeleteNaraElement';
/* attributes - 기준정보/항목 */
import MarketAttributeManagement from 'pages/attributes/MarketAttributeManagement';
/* account - 기준정보/거래처 */
import NaraAccountManagement from 'pages/account/NaraAccountManagement';
/* contract - 주문/수주 */
import MarketContract from 'pages/contract/custom/common/market/MarketContract';
import MarketCopyContract from 'pages/contract/custom/common/market/crud/CopyContract';
import MarketCreateContract from 'pages/contract/custom/common/market/crud/CreateContract';
import CreateMarketContract from 'pages/contract/custom/common/market/crud/CreateMarketContract';
import MarketUpdateContract from 'pages/contract/custom/common/market/crud/UpdateContract';

/* 경서 */
/* element - 기준정보/물품 */
import GsElementManagement from 'pages/element/custom/company/gs/GsElementManagement';
/* contract - 주문/수주 */
import GSContract from 'pages/contract/custom/company/gs/GSContract';
import CreateGSContract from 'pages/contract/custom/company/gs/crud/CreateGSContract';
import UpdateGSContract from 'pages/contract/custom/company/gs/crud/UpdateGSContract';
import DeleteGSContract from 'pages/contract/custom/company/gs/crud/DeleteGSContract';
/* procurement - 주문/발주 */
import GSProcurement from 'pages/procurement/custom/company/gs/GSProcurement';
import CreateGSProcurement from 'pages/procurement/custom/company/gs/crud/CreateGSProcurement';
import UpdateGSProcurement from 'pages/procurement/custom/company/gs/crud/UpdateGSProcurement';
import DeleteGSProcurement from 'pages/procurement/custom/company/gs/crud/DeleteGSProcurement';
/* procurement/management */
import GSNotInStock from 'pages/procurement/custom/company/gs/management/GSNotInStock';
/* shipment - 주문/출하 */
import GSShipmentManagement from 'pages/shipment/custom/company/gs/GSShipmentManagement';
import CopyGSShipment from 'pages/shipment/custom/company/gs/crud/CopyGSShipment';
import CreateGSShipment from 'pages/shipment/custom/company/gs/crud/CreateGSShipment';
import UpdateGSShipment from 'pages/shipment/custom/company/gs/crud/UpdateGSShipment';
import DeleteGSShipment from 'pages/shipment/custom/company/gs/crud/DeleteGSShipment';
/* stock - 재고/현재고 */
import GsCurrentStock from 'pages/stock/custom/company/gs/GsCurrentStock';
/* stockHistory - 재고상세이력 */
import GSStockHistory from 'pages/stockHistory/custom/company/gs/GSStockHistory';
import GsStockHistory2 from 'pages/stockHistory/custom/company/gs/GsStockHistory2';
/* productionOrder - 생산관리/작업지시 */
import GSProductionOrder from 'pages/productionOrder/custom/company/gs/GSProductionOrder';
import CopyGSProductionOrder from 'pages/productionOrder/custom/company/gs/crud/CopyGSProductionOrder';
import CreateGSProductionOrder from 'pages/productionOrder/custom/company/gs/crud/CreateGSProductionOrder';
import UpdateGSProductionOrder from 'pages/productionOrder/custom/company/gs/crud/UpdateGSProductionOrder';
import DeleteGSProductionOrder from 'pages/productionOrder/custom/company/gs/crud/DeleteGSProductionOrder';
/* productionStatus - 생산관리/작업현황 */
import GSProductionStatus from 'pages/productionStatus/custom/company/gs/GSProductionStatus';
/* productionPerformance - 생산관리/작업실적 */
import GSProductionPerformance from 'pages/productionPerformance/custom/company/gs/GSProductionPerformance';
/* manHour - 생산관리/공수관리 */
import GSManHourManagement from 'pages/manHour/custom/company/gs/GSManHourManagement';
import GSManHourResult from 'pages/manHour/custom/company/gs/result/GSManHourResult';
import GSWorker from 'pages/manHour/custom/company/gs/result/contents/Worker';
/* working - 제조실행/작업실행 */
import GSWorkerScreen from 'pages/working/custom/company/gs/GSWorkerScreen';
/* qualityControl - 품질/품질관리 */
import GSQualityControl from 'pages/qualityControl/custom/company/gs/GSQualityControl';
/* incomingInspection - 품질/조도측정 */
import GSIncomingInspection from 'pages/incomingInspection/custom/company/gs/GSIncomingInspection';
import CreateGSIncomingInspection from 'pages/incomingInspection/custom/company/gs/crud/CreateGSIncomingInspection';
import DeleteGSIncomingInspection from 'pages/incomingInspection/custom/company/gs/crud/DeleteGSIncomingInspection';
/* monitoring - 모니터링/모니터링 */
import GSTotalMonitoring from 'pages/monitoring/custom/company/gs/GSTotalMonitoring';
/* fems - 모니터링/ESG모니터링 */
import GSEsgMonitoring from 'pages/fems/gs/combine';
import GSEsg from 'pages/fems/gs/GSEsg';
import GSEsgSearch from 'pages/fems/gs/contents/Search';
/* barcode - 바코드 */
import ScanGSBarcode from 'pages/barcode/custom/company/gs/ScanGSBarcode';
import PrintGSBarcode from 'pages/barcode/custom/company/gs/PrintGSBarcode';

/* 우리창호 */
/* stockHistory - 재고상세이력 */
import UriStockHistory from 'pages/stockHistory/custom/company/uri/UriStockHistory';
/* productionOrder - 생산관리/작업지시 */
import UriProductionOrder from 'pages/productionOrder/custom/company/uri/UriProductionOrder';
import CreateUriProductionOrder from 'pages/productionOrder/custom/company/uri/crud/CreateUriProductionOrder';
import UpdateUriProductionOrder from 'pages/productionOrder/custom/company/uri/crud/UpdateUriProductionOrder';
import DeleteUriProductionOrder from 'pages/productionOrder/custom/company/uri/crud/DeleteUriProductionOrder';
/* productionStatus - 생산관리/작업현황 */
import UriProductionStatus from 'pages/productionStatus/custom/company/uri/UriProductionStatus';
/* productionPerformance - 생산관리/작업실적 */
import UriProductionPerformance from 'pages/productionPerformance/custom/company/uri/UriProductionPerformance';
/* calendar - 생산관리/일일계획(일정관리) */
import UriCalendarPlan from 'pages/calendar/custom/company/uri/UriCalendarPlan';
/* working - 제조실행/작업실행 */
import UriWorkerScreen from 'pages/working/custom/company/uri/UriWorkerScreen';
// 공정리스트
import UriCalendarWorkerScreen from 'pages/working/custom/company/uri/process/UriCalendarWorkerScreen';
/* qualityControl - 품질/품질관리 */
import UriQualityControl from 'pages/qualityControl/custom/company/uri/UriQualityControl';
/* monitoring - 모니터링/모니터링 */
import UriTotalMonitoring from 'pages/monitoring/custom/company/uri/UriTotalMonitoring';
/* sensorData - 모니터링/센서데이터 */
import UriSensorData from 'pages/sensorData/custom/company/uri/UriSensorData';
/* scheduler - 모니터링/작업일정 */
import UriScheduler from 'pages/scheduler/custom/company/uri/UriScheduler';

/* 엠에스코리아 */
/* monitoring - 모니터링/모니터링 */
import MSTotalMonitoring from 'pages/monitoring/custom/company/ms/MSTotalMonitoring';

/* 대림비엔코 */
/* movement - 제조실행/공정이동표 */
import DLMovement from 'pages/custom/company/dl/movement/DLMovement';
import CreateDLMovement from 'pages/custom/company/dl/movement/crud/CreateDLMovement';
import UpdateDLMovement from 'pages/custom/company/dl/movement/crud/UpdateDLMovement';
import DeleteDLMovement from 'pages/custom/company/dl/movement/crud/DeleteDLMovement';
/* sensorData - 모니터링/센서데이터 */
import DLSensorData from 'pages/sensorData/custom/company/dl/DLSensorData';

/* 금산홍삼랜드 */
/* sensorData - 모니터링/센서데이터 */
import HsSensorData from 'pages/sensorData/custom/company/hsland/HsSensorData';

/* 지엘팜 */
/* sensorData - 모니터링/센서데이터 */
import GLSensorData from 'pages/sensorData/custom/company/gl/GLSensorData';

/* 비에이에너지 */
/* sensorData - 모니터링/센서데이터 */
import BaSensorData from 'pages/sensorData/custom/company/ba/BaSensorData';

/* 아침엔글로벌 */
/* sensorData - 모니터링/온도/습도 데이터 */
import MgSensorData from 'pages/sensorData/custom/company/mg/MgSensorData';

/* 팸텍 */
/* sensorData - 모니터링/센서(설비) 데이터 */
import SensorManagement from 'pages/safetyManagement/custom/SensorManagement';

/* 한바다다 */
/* monitoring - 모니터링/생산, 금속검출 로우 데이터 */
import HbdKPIRowData from 'pages/kpi/HbdKPIRowData';

export {
  // 나라장터
  // 기준정보
  NaraElement, NaraElementV2, CopyNaraElement, CreateNaraElement, UpdateNaraElement, DeleteNaraElement,
  MarketAttributeManagement,
  NaraAccountManagement,
  // 주문
  MarketContract, MarketCopyContract, MarketCreateContract, CreateMarketContract, MarketUpdateContract,

  // 경서
  // 기준정보
  GsElementManagement,
  // 주문
  GSContract, CreateGSContract, UpdateGSContract, DeleteGSContract,
  GSProcurement, CreateGSProcurement, UpdateGSProcurement, DeleteGSProcurement,
  GSNotInStock,
  GSShipmentManagement, CopyGSShipment, CreateGSShipment, UpdateGSShipment, DeleteGSShipment,
  // 재고
  GsCurrentStock,
  GSStockHistory, GsStockHistory2,
  // 생산관리
  GSProductionOrder, CopyGSProductionOrder, CreateGSProductionOrder, UpdateGSProductionOrder, DeleteGSProductionOrder,
  GSProductionStatus,
  GSProductionPerformance,
  GSManHourManagement, GSManHourResult, GSWorker,
  // 제조실행
  GSWorkerScreen,
  // 품질
  GSQualityControl,
  GSIncomingInspection, CreateGSIncomingInspection, DeleteGSIncomingInspection,
  // 모니터링
  GSTotalMonitoring,
  GSEsgMonitoring, GSEsg, GSEsgSearch,
  // 바코드
  ScanGSBarcode, PrintGSBarcode,

  // 우리창호
  // 재고
  UriStockHistory,
  // 생산관리
  UriProductionOrder, CreateUriProductionOrder, UpdateUriProductionOrder, DeleteUriProductionOrder,
  UriProductionStatus,
  UriProductionPerformance,
  UriCalendarPlan,
  // 제조실행
  UriWorkerScreen, UriCalendarWorkerScreen,
  // 품질
  UriQualityControl,
  // 모니터링
  UriTotalMonitoring,
  UriSensorData,
  UriScheduler,

  // 엠에스코리아
  // 모니터링
  MSTotalMonitoring,

  // 대림비엔코
  // 생산관리
  DLMovement, CreateDLMovement, UpdateDLMovement, DeleteDLMovement,
  // 모니터링
  DLSensorData,

  // 금산홍삼랜드
  // 모니터링
  HsSensorData,

  // 지엘팜
  // 모니터링
  GLSensorData,

  // 비에이에너지
  // 모니터링
  BaSensorData,

  // 아침엔글로벌
  // 모니터링
  MgSensorData,
  
  // 팸텍
  // 모니터링
    SensorManagement,

  // 한바다
  // 모니터링
  HbdKPIRowData,
};
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { accountTypeApi } from 'api/apis/accountTypeApi';
import { contractTypeApi } from 'api/apis/contractTypeApi';
import { processTypeApi } from 'api/apis/processTypeApi';
import { shipmentInspectionItemType } from 'api/apis/shipmentInspection/shipmentInspectionItemType';
import { stockPlace } from 'api/apis/stock/stockPlace';
import { workOrderTypeApi } from 'api/apis/workOrderTypeApi';
import { claimTypeApi } from 'api/apis/claim/claimTypeApi';

import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';

const DeleteType = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();

  const type = searchParams.get('type');

  const accountTypeId = searchParams.get('accountTypeId');
  const accountTypeName = searchParams.get('accountTypeName');
  const contractTypeId = searchParams.get('contractTypeId');
  const contractTypeName = searchParams.get('contractTypeName');
  const processTypeId = searchParams.get('processTypeId');
  const processTypeName = searchParams.get('processTypeName');
  const workOrderTypeId = searchParams.get('workOrderTypeId');
  const workOrderTypeName = searchParams.get('workOrderTypeName');
  const shipmentInspectionItemTypeId = searchParams.get('shipmentInspectionItemTypeId');
  const shipmentInspectionItemTypeName = searchParams.get('shipmentInspectionItemTypeName');
  const stockPlaceId = searchParams.get('stockPlaceId');
  const placeName = searchParams.get('placeName');
  const customerClaimTypeId = searchParams.get('customerClaimTypeId');
  const customerClaimTypeName = searchParams.get('customerClaimTypeName');

  /* ====================================================================== #3 */

  /* ====================================================================== #4 */

  /* ====================================================================== #5 */
  const actDelete_accountType = async () => {
    await accountTypeApi.deleteAccountType(accountTypeId).then((response) => {
      if (response === undefined) return;
      console.log('accountTypeApi.deleteAccountType : ', response);

      alert('거래처 유형을 삭제했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  const actDelete_contractType = async () => {
    await contractTypeApi.deleteContractType(contractTypeId).then((response) => {
      if (response === undefined) return;
      console.log('contractTypeApi.deleteContractType : ', response);

      alert('수주 유형을 삭제했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  const actDelete_processType = async () => {
    await processTypeApi.deleteProcessType(processTypeId).then((response) => {
      if (response === undefined) return;
      console.log('processTypeApi.deleteProcessType : ', response);

      alert('공정 유형을 삭제했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  const actDelete_workOrderType = async () => {
    await workOrderTypeApi.deleteWorkOrderType(workOrderTypeId).then((response) => {
      if (response === undefined) return;
      console.log('workOrderTypeApi.deleteWorkOrderType : ', response);

      alert('작업 유형을 삭제했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  const actDelete_shipmentInspectionItemType = async () => {
    await shipmentInspectionItemType.deleteShipmentInspectionItemType(shipmentInspectionItemTypeId).then((response) => {
      if (response === undefined) return;
      console.log('shipmentInspectionItemType.deleteShipmentInspectionItemType : ', response);

      alert('점검 / 검사 항목을 삭제했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  const actDelete_stockPlace = async () => {
    await stockPlace.deleteStockPlace(stockPlaceId).then((response) => {
      if (response === undefined) return;
      console.log('stockPlace.deleteStockPlace : ', response);

      alert('재고 장소를 삭제했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  const actDelete_claimType = async () => {
    await claimTypeApi.deleteClaimType(customerClaimTypeId).then((response) => {
      if (response === undefined) return;
      console.log('deleteClaimType : ', response);

      alert('클레임 유형을 삭제했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body
      contents={
        <>
          <main className="Main">
            <NavBar
              title={
                type === 'accountType'
                  ? '거래처 유형 정의'
                  : type === 'contractType'
                    ? '수주 유형 정의'
                    : type === 'processType'
                      ? '공정 유형 정의'
                      : type === 'workOrderType'
                        ? '작업 유형 정의'
                        : type === 'shipmentInspectionItemType'
                          ? '점검 / 검사 항목 정의'
                          : type === 'stockPlace'
                            ? '재고 장소 정의'
                            : type === 'claimType'
                              ? '클레임 유형 정의'
                              : null
              }
              nav={''}
            />
            <DeleteForm
              delteTitle={
                type === 'accountType'
                  ? '거래처 유형'
                  : type === 'contractType'
                    ? '수주 유형'
                    : type === 'processType'
                      ? '공정 유형'
                      : type === 'workOrderType'
                        ? '작업 유형'
                        : type === 'shipmentInspectionItemType'
                          ? '점검 / 검사 항목'
                          : type === 'stockPlace'
                            ? '재고 장소'
                            : type === 'claimType'
                              ? '클레임 유형'
                              : null
              }
              deleteItem={
                <>
                  {type === 'accountType'
                    ? '거래처 유형'
                    : type === 'contractType'
                      ? '수주 유형'
                      : type === 'processType'
                        ? '공정 유형'
                        : type === 'workOrderType'
                          ? '작업 유형'
                          : type === 'shipmentInspectionItemType'
                            ? '점검 / 검사 항목'
                            : type === 'stockPlace'
                              ? '재고 장소'
                              : type === 'claimType'
                                ? '클레임 유형'
                                : null}{` : `}
                  {type === 'accountType'
                    ? accountTypeName
                    : type === 'contractType'
                      ? contractTypeName
                      : type === 'processType'
                        ? processTypeName
                        : type === 'workOrderType'
                          ? workOrderTypeName
                          : type === 'shipmentInspectionItemType'
                            ? shipmentInspectionItemTypeName
                            : type === 'stockPlace'
                              ? placeName
                              : type === 'claimType'
                                ? customerClaimTypeName
                                : null}
                  <br />
                </>
              }
              deleteText={
                <>
                  {type === 'accountType'
                    ? '거래처 유형'
                    : type === 'contractType'
                      ? '수주 유형'
                      : type === 'processType'
                        ? '공정 유형'
                        : type === 'workOrderType'
                          ? '작업 유형'
                          : type === 'shipmentInspectionItemType'
                            ? '점검 / 검사 항목'
                            : type === 'stockPlace'
                              ? '재고 장소'
                              : type === 'claimType'
                                ? '클레임 유형'
                                : null}
                  에 포함된{' '}
                  <span className="cautionText">
                    모든{' '}
                    {type === 'accountType'
                      ? '거래처 데이터'
                      : type === 'contractType'
                        ? '수주 데이터'
                        : type === 'processType'
                          ? '공정 데이터'
                          : type === 'workOrderType'
                            ? '작업 데이터'
                            : type === 'shipmentInspectionItemType'
                              ? '점검 / 검사 항목 데이터'
                              : type === 'claimType'
                                ? '클레임'
                                : '데이터'}
                    {' '}정보
                  </span>
                  가 삭제됩니다.
                </>
              }
              deleteButton={
                <>
                  <button
                    className="formButton cancle"
                    onClick={() => {
                      navigate(pageReducer.currentPage, { replace: true });
                    }}
                  >
                    취소
                  </button>
                  <button
                    className="formButton delete"
                    onClick={() => {
                      if (window.confirm('삭제하시겠습니까?')) {
                        if (type === 'accountType') {
                          actDelete_accountType();
                        } else if (type === 'contractType') {
                          actDelete_contractType();
                        } else if (type === 'processType') {
                          actDelete_processType();
                        } else if (type === 'workOrderType') {
                          actDelete_workOrderType();
                        } else if (type === 'shipmentInspectionItemType') {
                          actDelete_shipmentInspectionItemType();
                        } else if (type === 'stockPlace') {
                          actDelete_stockPlace();
                        } else if (type === 'claimType') {
                          actDelete_claimType();
                        } else return;
                    }}}
                  >
                    삭제
                  </button>
                </>
              }
            />
          </main>
        </>
      }
    />
  );
};

export default DeleteType;

import React, { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import {
  checkEmptyNull,
  checkNullArray,
} from 'components/checkValues/checkValues';
import TableSection from 'components/layouts/table/TableSection';

const KPIRowTable = ({ unit, contents, weeks }) => {
  const [_current, setCurrent] = useState(0);
  const [_target, setTarget] = useState(0);
  const [_performance, setPerformance] = useState(0);
  const [_achievement, setAchievement] = useState(0);
  const [_goalRate, setGoalRate] = useState(0);

  useEffect(() => {
    const current = checkEmptyNull(unit.current, 0) * 1;
    const target = checkEmptyNull(unit.target, 0) * 1;

    const totalValue = checkNullArray(contents, [])
      .map((content) => content.value * 1)
      .reduce((a, b) => a + b, 0);

    const averageValue = totalValue / contents.length;
    const performance =
      unit.realDtlCd === 'shorten' || unit.realDtlCd === 'failRate'
        ? averageValue
        : unit.cycle === 'WEEK'
        ? totalValue / weeks
        : totalValue;

    const isIncreaseTarget =
      unit.realDtlCd === 'production' || unit.realDtlCd === '매출';
    const achievement = isIncreaseTarget
      ? BigNumber(performance)
          .minus(current)
          .dividedBy(current)
          .multipliedBy(100)
          .toNumber()
      : BigNumber(current)
          .minus(performance)
          .dividedBy(current)
          .multipliedBy(100)
          .toNumber();

    const goalRate = isIncreaseTarget
      ? BigNumber(performance).dividedBy(target).multipliedBy(100).toNumber()
      : BigNumber(target).dividedBy(performance).multipliedBy(100).toNumber();

    setCurrent(current);
    setTarget(target);
    setPerformance(performance);
    setAchievement(achievement);
    setGoalRate(goalRate);
  }, [unit, contents, weeks]);

  return (
    <section className="section-table">
      <TableSection
        content={
          <table>
            <thead>
              <tr>
                <th>일자</th>
                <th>{unit.unit}</th>
              </tr>
            </thead>
            <tbody>
              {checkNullArray(contents, []).length === 0 ? (
                <tr>
                  <td colSpan={2}>데이터가 없습니다</td>
                </tr>
              ) : (
                checkNullArray(contents, []).map((data, index) => (
                  <tr key={index}>
                    <td>{data.occurrenceDate}</td>
                    <td>
                      {(checkEmptyNull(data.value, 0) * 1).toLocaleString()}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        }
      />
    </section>
  );
};

export default KPIRowTable;

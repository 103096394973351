import React, { useState } from 'react';
import moment from 'moment';

import { claimApi } from 'api/apis/claim/claimApi';

const UpdateClaimForm = (props) => {
  /* ====================================================================== #1 */
  /* ====================================================================== #2 */
  const [_formData, setFormData] = useState({
    accountId: props.updateClaim.accountId, // 고객 아이디(번호)
    accountName: props.updateClaim.accountName, // 고객 명
    customerClaimTypeId: props.updateClaim.customerClaimTypeId, // 클레임 유형
    customerClaimContent: props.updateClaim.customerClaimContent, // 클레임 내용
    customerClaimDate: moment().format('YYYY-MM-DD'), // 수정일
  });

  /* ====================================================================== #3 */
  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value }; });
  };

  /* 수정 */
  const actUpdate = async (e) => {
    e.preventDefault();

    const claimId = props.updateClaim.customerClaimId;
    const BodyToPost = { ..._formData };
    console.log('BodyToPost : ', BodyToPost);

    await claimApi.updateClaim(claimId, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('claim.updateClaim : ', response);
      alert('클레임을 수정했습니다.');
      props.update();
      props.close();
    });
  };

  /* ====================================================================== #6 */

  return (
    <section className='claim-form'>
      <div>
        <div className='claim-form-title'>
          <h2>클레임 수정</h2>
          <button onClick={props.close}>닫기</button>
        </div>
        <div className='claim-form-input'>
          <select name='customerClaimTypeId' onInput={handleInputEvent}>
            {props.claimTypeList.map((claimType) => {
              return (
                <option key={claimType.customerClaimTypeId + '_claimType'} value={claimType.customerClaimTypeId}>{claimType.customerClaimTypeName}</option>
              );
            })}
          </select>
          <input
            type='number'
            name='accountId'
            placeholder='고객 번호'
            value={_formData.accountId}
            onInput={handleInputEvent}
          />
          <input
            type='text'
            name='accountName'
            placeholder='고객명'
            value={_formData.accountName}
            onInput={handleInputEvent}
          />
        </div>
        <textarea
          type="text"
          name="customerClaimContent"
          placeholder="클레임 내용을 이곳에 작성해주세요."
          value={_formData.customerClaimContent}
          onInput={handleInputEvent}
        />
        <button className="submit" onClick={actUpdate}>클레임 수정</button>
      </div>
    </section>
  );
};

export default UpdateClaimForm;
import styled from 'styled-components';

export const ClaimStyle = styled.div`
  /* 페이지 - 고객 클레임 관리 리스트 */
  .claimList {
    display: flex; flex-direction: column;
    section {
      background: #fff;
      font-size: 1.25rem;
      overflow: auto;
      position: relative;
      .claim-title {
        padding: 8px 16px; border-bottom: 1px solid #ccc;
        display: flex; justify-content: space-between; align-items: center;
        text-align: center;
        border-top: 1px solid #ccc;
        font-weight: bold;
        position: sticky; top: 0; left: 0; background: #fff;
        box-shadow: 0 2px 6px 0 rgba(196, 196, 196, 0.8);
      }
      ul {
        li {
          padding: 8px 16px; border-bottom: 1px solid #ccc;
          display: flex; justify-content: space-between; align-items: center;
          text-align: center;
        }
      }
      .claim-check { width: 5%; }
      .claim-type { width: 15%; }
      .claim-accountName { width: 15%; }
      .claim-content { width: 45%; }
      .claim-date { width: 20%; }
    }
    /* 클레임 추가 버튼 */
    .claim-form-btn { background: var(--MainBlue); border-radius: 8px; color: var(--white); display: flex; gap: 8px; padding: 4px 16px; position: relative;}
    
    /* 모달 */
    .claim-form {
      width: 100%; height: 94%; z-index: 99;
      display: flex; flex-direction: column; align-items: center; justify-content: center;
      position: absolute; top: 60px; left: 0;
      background: #eee;
      .claim-form-title {
        display: flex; justify-content: space-between;
        /* 닫기 버튼 */
        button { white-space: nowrap; border-radius: 8px; padding: 4px 16px; background: var(--color-2); color: var(--white);}
      }
      .claim-form-input {
        width: 1000px;
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        select {
          border-radius: 8px;
          border: 1px solid #ccc;
          resize: none;
          padding: 5px 10px;
          text-align: center;
        }
        input {
          border-radius: 8px;
          border: 1px solid #ccc;
          resize: none;
          padding: 5px;
          text-align: center;
        }
      }
      textarea { width: 1000px; height: 200px; border-radius: 8px; border: 1px solid #ccc; resize: none; padding: 16px; }
      .submit { margin-top: 20px; width: 100%; padding: 20px; border-radius: 8px; background: var(--color-2); color: var(--white); }
      .claim-form-content { margin: 20px 0; }
      .submit-delete { width: 100%; padding: 20px 50px; border-radius: 8px; background: var(--color-1); color: var(--white); }
    }

    /* 검색 */
    .qna-search {
      display: flex;
      gap: 8px;
      justify-content: space-between;
      .SearchOption-detail {
        border-radius: 8px;
        border: 1px solid #ccc;
        resize: none;
        padding: 2px 10px;
        text-align: center;
      }
    }

  }
`;

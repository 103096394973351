import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import {
  checkEmptyNull,
  checkNullArray,
} from 'components/checkValues/checkValues';

import { kpi } from 'api/apis/kpi/kpi';

import { Main } from './KPIMonitoring.style';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';

import KPIRowTable from 'pages/kpi/contents/KPIRowTable';
import { useParams } from 'react-router-dom';

export default function HbdKPIRowData() {
  const { id } = useParams(); // URL 매개변수 가져오기
  const { userReducer } = useSelector((state) => state);
  
  const [_kpiYear, setKpiYear] = useState(moment().year());
  const [_kpiUnits, setKpiUnits] = useState([]); // KPI 유형 리스트
  const [_kpiUnit, setKpiUnit] = useState({}); // KPI 유형

  const months = Array.from({ length: 12 }, (_, index) =>
    (index + 1).toString().padStart(2, '0'),
  );
  const [_month, setMonth] = useState('01');
  const [_weeks, setWeeks] = useState(5);

  const [_kpiDataList, setKpiDataList] = useState([]);

  const data = {
    1: '생산 데이터',
    2: '클레임 데이터',
    3: '금속 검출 데이터',
  };

  useEffect(() => {

    getKpiUnits(_kpiYear);

    findDays(_kpiYear, _month);

    return () => {};
  }, []);

  useEffect(() => {}, [
    _kpiYear,
    _kpiUnits,
    _kpiUnit,
    _kpiDataList,
    _month,
    _weeks,
    id,
  ]);

  const getKpiUnits = async (year) => {
    // KPI 유형 리스트 불러오기

    // 로컬에서 모헨에서 2024-1 로 조회하면 확인할 수 있음
    const body = {
      companyId: userReducer.company.companyId,
      type: 'shop',
      year: Number(year),
    };
    await kpi.searchKpiUnit(body).then((response) => {
      if (response === undefined) return;

      let returnData = response.data || [];

      // `id` 값을 활용해 특정 인덱스 데이터만 설정
      const selectedData =
        id && returnData.length > 0 ? returnData[Number(id) - 1] : {};

      setKpiUnits(returnData);
      setKpiUnit(selectedData); // 특정 데이터 설정
      if (selectedData.id) {
        getKpiData(selectedData, _month); // 해당 데이터에 맞는 KPI 데이터 조회
      }

      console.log('KPI 유형 리스트 (_kpiUnits):', returnData);
    });
  };
  const getKpiData = async (kpiUnit, month) => {
    // KPI 데이터 불러오기
    if (!checkEmptyNull(month, false)) return;

    const fromDate = `${_kpiYear}-${month}-01`;
    const toDate = moment(fromDate).endOf('month').format('YYYY-MM-DD');

    const body = {
      fromDate: fromDate,
      toDate: toDate,
      kpiUnitId: kpiUnit.id,
    };

    // 아침엔글로벌
    if (kpiUnit.id === 43 || kpiUnit.id === 44) {
      // 요청한 시작 날짜가 9월 이전인 경우 수정
      if (Number(_kpiYear) < 2025) {
        if (Number(month) < 9 || Number(month) > 12) {
          body.fromDate = `0000-01-01`;
          body.toDate = `0000-01-31`;
        }
      }
      await kpi.searchKpiData(body).then((response) => {
        if (response === undefined) return;
        setKpiDataList(() => response.data);
      });
    }

    await kpi.searchKpiData(body).then((response) => {
      if (response === undefined) return;
      setKpiDataList(() => {
        return response.data;
      });
    });
  };

  const findDays = (kpiYear, month) => {
    // 선택한 달의 주수, 일수 구하기
    const firstDay = moment(`${kpiYear}-${month}-01`);
    const lastDay = moment(firstDay).endOf('month');
    const firstDayOfWeek = firstDay.day() === 0 ? 7 : firstDay.day();
    const lastWeekNo = Math.ceil((firstDayOfWeek - 1 + lastDay.date()) / 7);
    setWeeks(lastWeekNo);
  };

  const actReset = () => {
    // 초기화
    setKpiYear(() => {
      return '';
    });
    setKpiUnits(() => {
      return [];
    });
    setKpiUnit(() => {
      return {};
    });
    setMonth(() => {
      return '01';
    });
    setKpiDataList(() => {
      return [];
    });
  };

  const getTitle = (id) => {
    return <h4>{data[id] || <NavTitle menuCode="706" />}</h4>;
  };


  return (
    <Grid2Body
      contents={
        <Main>
          <NavBar
            title={getTitle(id)}
            buttons={
              <>
                <div className="SearchSection">
                  <div className="SearchSection">
                    <input
                      type="text"
                      className="search-select year"
                      value={_kpiYear}
                      onChange={(e) => {
                        const year = e.target.value;
                        setKpiYear(() => {
                          return year;
                        }); // 연도조회
                        if (year.length >= 4) {
                          setKpiUnits(() => {
                            return [];
                          });
                          setKpiUnit(() => {
                            return {};
                          });
                          setMonth(() => {
                            return '01';
                          });
                          setKpiDataList(() => {
                            return [];
                          });
                          findDays(year, '01');

                          getKpiUnits(year);
                        }
                      }}
                    />
                    <span>년</span>
                  </div>

                  {checkEmptyNull(_kpiYear, false) &&
                  checkNullArray(_kpiUnits, false) ? (
                    <>
                      <div className="SearchSection">
                        <select
                          className="SelectType"
                          style={{ marginInlineEnd: '10px', width: '180px' }}
                          value={_kpiUnit.id || ''}
                          disabled // 선택 불가 설정
                        >
                          {checkNullArray(_kpiUnits, [])
                            .filter((_, index) => index === Number(id) - 1) // 특정 인덱스 필터링
                            .map((unit, index) => {
                              if (Array.isArray(unit.kpiDataDtoList)) {
                                return (
                                  <option key={index + '_unit'} value={unit.id}>
                                    {unit.kpiCode.kpiDtlNm}
                                  </option>
                                );
                              } else return null;
                            })}
                        </select>
                      </div>

                      {checkEmptyNull(_kpiUnit?.id, false) ? (
                        <div className="SearchSection">
                          <div className="ContentCBox">
                            <select
                              className="search-select "
                              value={_month}
                              onChange={(e) => {
                                const month = e.target.value;
                                setMonth(() => {
                                  return e.target.value;
                                });
                                getKpiData(_kpiUnit, month);
                                findDays(_kpiYear, month);
                              }}
                            >
                              {months.map((month, index) => {
                                return (
                                  <option key={index + '_month'} value={month}>
                                    {index + 1}월
                                  </option>
                                );
                              })}
                            </select>
                          </div>

                          <div className="ContentCBox">
                            <button
                              className="ResetButton"
                              onClick={(e) => {
                                e.preventDefault();
                                actReset();
                              }}
                            >
                              초기화
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </div>
              </>
            }
            nav={''}
          />

          <div className="contents">
            <div className="contentWrap">
              {checkEmptyNull(_kpiUnit?.id, false) ? (
                <KPIRowTable
                  unit={_kpiUnit}
                  contents={_kpiDataList}
                  weeks={_weeks}
                />
              ) : null}
            </div>
          </div>
        </Main>
      }
    />
  );
};

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { sensorDataApi } from 'api/apis/sensorDataApi';

import { DashboardBtn, SafetyMain } from '../SafetyManagement.style';

import GridNonBody from 'components/layouts/body/GridNonBody';
import Header from 'components/header/Header';
import SensorMonitoring from './SensorMonitoring';

const processData = (logList = []) => {
  return {
    CH1Data: logList.find((log) => log.name.includes('CH1')) || {
      name: 'CH1',
      value: 'CH1 데이터 없음',
    },
    CH2Data: logList.find((log) => log.name.includes('CH2')) || {
      name: 'CH2',
      value: 'CH2 데이터 없음',
    },
    CH3Data: logList.find((log) => log.name.includes('CH3')) || {
      name: 'CH3',
      value: 'CH3 데이터 없음',
    },
    CH4Data: logList.find((log) => log.name.includes('CH4')) || {
      name: 'CH4',
      value: 'CH4 데이터 없음',
    },
    PVData: logList.find((log) => log.name.includes('PV')) || {
      name: 'PV',
      value: 'PV 데이터 없음',
    },
  };
};


const SensorManagement = () => {
  const { userReducer } = useSelector((state) => state);

  const [_onHeader, setOnHeader] = useState(false);
  const [_pageNumber, setPageNumber] = useState(1);
  const [_startDate, setStartDate] = useState(
    moment().add('-1', 'm').format('YYYY-MM-DD hh:mm:ss'),
  );
  const [_endDate, setEndDate] = useState(
    moment().format('YYYY-MM-DD hh:mm:ss'),
  );
  const [_logList, setLogList] = useState([]);
  const [_sensorData, setSensorData] = useState({});

  // ---

  // useEffect(() => {
  //   // getSensorData(_pageNumber - 1, userReducer.company.companyId);
  //   const fixedCompanyId = 44; // 팸텍 companyId = 44
  //   getSensorData(_pageNumber - 1, fixedCompanyId);
  //   return () => {};
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      const fixedCompanyId = 44; // 팸텍 companyId = 44
      const page = _pageNumber - 1;

      // 데이터 호출
      const paging = `?page=${page}&size=100&sort=id,DESC`;
      const BodyToPost = {
        companyId: fixedCompanyId,
        startDate: moment().add('-1', 'm').add(9, 'hour').toISOString(),
        endDate: moment().add(9, 'hour').toISOString(),
        name: '',
      };

      const response = await sensorDataApi.countData(paging, BodyToPost);
      if (response) {
        const filteredLogs = response.data.content.reduce((acc, log) => {
          if (!acc.some((item) => item.name === log.name)) {
            acc.push(log);
          }
          return acc;
        }, []);
        setLogList(filteredLogs);

        // 데이터 가공
        const processedData = processData(filteredLogs);
        setSensorData(processedData);
      }
    };

    fetchData();
  }, [_pageNumber]);

  // ---

  const clickHeader = (e) => {
    e.preventDefault();
    setOnHeader((prev) => !prev);
  };

  // ---

  return (
    <>
      <GridNonBody
        header={<>{_onHeader ? <Header /> : null}</>}
        contents={
          <SafetyMain onHeader={_onHeader}>
            <section className="sensor-layout">
              <SensorMonitoring data={_sensorData.CH1Data} />
              <SensorMonitoring data={_sensorData.CH2Data} />
              <SensorMonitoring data={_sensorData.CH3Data} />
              <SensorMonitoring data={_sensorData.CH4Data} />
              <SensorMonitoring data={_sensorData.PVData} />
            </section>
          </SafetyMain>
        }
      />
      <DashboardBtn onClick={clickHeader} data-name="on">
        메뉴 {_onHeader ? '닫기' : '열기'}
      </DashboardBtn>
    </>
  );
};

export default SensorManagement;

export const claimActions_setClaimType = (type) => {
  return {
    type: 'SETCLAIMTYPE',
    payload: type,
  };
};
export const claimActions_setSearchOption = (searchOption) => {
  return {
    type: 'SETCLAIMSEARCHOPTION',
    payload: searchOption,
  };
};
export const claimActions_setSearchText = (searchText) => {
  return {
    type: 'SETCLAIMSEARCHTEXT',
    payload: searchText,
  };
};
export const claimActions_setPageNumber = (pageNumber) => {
  return {
    type: 'SETCLAIMPAGENUMBER',
    payload: pageNumber,
  };
};
export const claimActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETCLAIMTOTALSIZE',
    payload: totalSize,
  };
};
export const claimActions_setIsPushedSearchButton = (
  isPushedSearchButton,
) => {
  return {
    type: 'SETCLAIMISPUSHEDSEARCHBUTTON',
    payload: isPushedSearchButton,
  };
};
export const claimActions_setReset = () => {
  return {
    type: 'SETCLAIMRESET',
  };
};

import React, { useEffect, useState } from 'react';

import { TmpHmdSection } from 'pages/safetyManagement/SafetyManagement.style';

const SensorMonitoring = ({
  data = { name: 'Unknown', value: '데이터 없음' },
}) => {
  const { name, value } = data;
  // const [_sensorData, setSensorData] = useState([]);
  // const [CH1Data, setCH1Data] = useState(null);
  // const [CH2Data, setCH2Data] = useState(null);
  // const [CH3Data, setCH3Data] = useState(null);
  // const [CH4Data, setCH4Data] = useState(null);
  // const [PVData, setPVData] = useState(null);

  // useEffect(() => {
  //   console.log('logList:', logList);

  //   const CH1Data = logList.find((log) => log.name.includes('CH1'));
  //   setCH1Data(CH1Data?.value);

  //   const CH2Data = logList.find((log) => log.name.includes('CH2'));
  //   setCH2Data(CH2Data?.value);

  //   const CH3Data = logList.find((log) => log.name.includes('CH3'));
  //   setCH3Data(CH3Data?.value);

  //   const CH4Data = logList.find((log) => log.name.includes('CH4'));
  //   setCH4Data(CH4Data?.value);

  //   const PVData = logList.find((log) => log.name.includes('PV'));
  //   setPVData(PVData?.value);

  //   console.log('CH1Data:', CH1Data);
  //   console.log('CH2Data:', CH2Data);
  //   console.log('CH3Data:', CH3Data);
  //   console.log('CH4Data:', CH4Data);
  //   console.log('PVData:', PVData);
  // }, [logList]);

  return (
    <TmpHmdSection className="expanded">
      <div className="nav">
        <h3>{name}</h3>
      </div>
      <div className="pt-contents">
        <div>{value}</div>
      </div>
    </TmpHmdSection>
  );
};

export default SensorMonitoring;
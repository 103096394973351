import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { claimApi } from 'api/apis/claim/claimApi';

const CreateClaimForm = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector(state => state);
  /* ====================================================================== #2 */
  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    accountId: '', // 고객 아이디(번호)
    accountName: '', // 고객 명
    customerClaimTypeId: '', // 클레임 유형
    customerClaimContent: '', // 클레임 내용
    customerClaimDate: moment().format('YYYY-MM-DD'), // 등록일
  });

  /* ====================================================================== #3 */

  /* ====================================================================== #4 */

  /* ====================================================================== #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value }; });
  };

  /* 추가 */
  const actCreate = async (e) => {
    e.preventDefault();

    const BodyToPost = { ..._formData };
    console.log('BodyToPost : ', BodyToPost);

    await claimApi.createClaim(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('claim.createClaim : ', response);
      alert('클레임을 추가했습니다.');
      props.update();
    });

  };

  /* ====================================================================== #6 */

  return (
    <section className='claim-form'>
      <div>
        <div className='claim-form-title'>
          <h2>클레임 추가</h2>
          <button onClick={props.close}>닫기</button>
        </div>
        <div className='claim-form-input'>
          <select name='customerClaimTypeId' onInput={handleInputEvent}>
            {props.claimTypeList.map((claimType) => {
              return (
                <option key={claimType.customerClaimTypeId + '_claimType'} value={claimType.customerClaimTypeId}>{claimType.customerClaimTypeName}</option>
              );
            })}
          </select>
          <input
            type='number'
            name='accountId'
            placeholder='고객 번호'
            value={_formData.accountId}
            onInput={handleInputEvent}
          />
          <input
            type='text'
            name='accountName'
            placeholder='고객명'
            value={_formData.accountName}
            onInput={handleInputEvent}
          />
        </div>
        <textarea
          type="text"
          name="customerClaimContent"
          placeholder="클레임 내용을 이곳에 작성해주세요."
          value={_formData.customerClaimContent}
          onInput={handleInputEvent}
        />
        <button className="submit" onClick={actCreate}>클레임 등록</button>
      </div>
    </section>
  );
};

export default CreateClaimForm;
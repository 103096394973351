const initialState = {
  claimTypeId: '', // 클레임 유형
  searchOption: '', // 검색 옵션
  searchText: '', // 검색어
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const resetState = {
  claimTypeId: '',
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const claimReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETCLAIMTYPE':
      return {
        ...state,
        claimTypeId: action.payload,
      };
    case 'SETCLAIMSEARCHOPTION':
      return {
        ...state,
        searchOption: action.payload,
      };
    case 'SETCLAIMSEARCHTEXT':
      return {
        ...state,
        searchText: action.payload,
      };
    case 'SETCLAIMPAGENUMBER':
      return {
        ...state,
        pageNumber: action.payload,
      };
    case 'SETCLAIMTOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };
    case 'SETCLAIMISPUSHEDSEARCHBUTTON':
      return {
        ...state,
        isPushedSearchButton: action.payload,
      };
    case 'SETCLAIMRESET':
      return {
        ...state,
        ...resetState,
      };

    default:
      return state;
  }
};

export default claimReducer;
